<template>
    <div id="cursor" class="cta-cursor">
        <template v-if="displayContent">
            {{ cursorText }}
        </template>
        <img
            v-if="displayContent"
            :src="`/img/icons/arrow-${arrowDirection}.svg`"
            alt="Arrow"
        >
    </div>
</template>

<script setup lang="ts">
import gsap from 'gsap'

const cursorText = ref('')
const arrowDirection = ref('right')
const displayContent = ref(false)
const FollowBox = '#cursor'
let TL: gsap.core.Timeline

onMounted(() => {
    setupGsap()
})

onUnmounted(() => {
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('scroll', onScroll)
    TL?.kill()
})

function setupGsap () {
    gsap.set(FollowBox, {
        xPercent: -50,
        yPercent: -50,
        scale: 0.2
    })

    window.addEventListener('scroll', onScroll)

    window.addEventListener('mousemove', onMouseMove)
}

function onScroll () {
    gsap.to(FollowBox, {
        scale: 0.1,
        overwrite: 'auto',
        duration: 0.15
    })
}

function onMouseMove (e: MouseEvent) {
    const TL = gsap.timeline({
        defaults: { duration: 0.5, ease: 'none' }
    })
    gsap.to(FollowBox, {
        duration: 0.15,
        overwrite: 'auto',
        x: e.clientX,
        y: e.clientY,
        ease: 'power3.easeOut'
    })

    if (!e.target || !(e.target instanceof HTMLElement)) {
        return
    }
    const dataCTA = e.target?.getAttribute('data-cta')
    arrowDirection.value = e.target?.getAttribute('data-arrow') || 'right'
    const dataCursor = e.target?.getAttribute('data-cursor')

    if (!dataCTA) {
        cursorText.value = ''
        displayContent.value = false
        if (dataCursor || e.target.tagName === 'A' || e.target.tagName === 'BUTTON' || e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
            if (e.target.style.cursor !== 'none') {
                e.target.style.cursor = 'none'
            }
            if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
                TL.to(FollowBox, {
                    scaleY: 0.3,
                    scaleX: 0.02,
                    borderRadius: '2px',
                    overwrite: 'auto',
                    duration: 0.15
                })
            } else {
                TL.to(FollowBox, {
                    scale: 0.3,
                    overwrite: 'auto',
                    duration: 0.15
                })
            }
            return
        }
        TL.to(FollowBox, {
            scale: 0.1,
            borderRadius: '4rem',
            overwrite: 'auto',
            duration: 0.15
        })
        return
    }

    displayContent.value = true
    cursorText.value = dataCTA

    TL.to(FollowBox, {
        scale: 1,
        overwrite: 'auto',
        duration: 0.15
    })
}

</script>

<style scoped>
</style>
