<template>
    <header class="header container" :class="[darkHeader && 'dark']">
        <div class="header__mobile-container">
            <nuxt-link to="/" class="header__logo" data-cursor="true" data-arrow="left" @click="onCloseMobileMenu">
                Spellcaster
            </nuxt-link>

            <div
                class="header__toggle-mobile"
                :class="mobileMenuOpen && 'open'"
                @click="onToggleMobileMenu"
            >
                <div v-for="i in 3" :key="i">
                    -
                </div>
            </div>
        </div>

        <div
            class="header__nav-bar"
            :class="mobileMenuOpen && 'open'"
        >
            <div class="header__mobile-nav-bar-background" />

            <nav v-if="navBarItems?.length" class="header__nav nav">
                <ul v-for="menu in navBarItems" :key="menu.id" class="nav__list">
                    <li v-for="item in menu.menuItems" :key="item.id" class="nav__item">
                        <nuxt-link
                            v-if="item.name"
                            :to="item.name === 'Contact' ? '/?id=contact' : `/${item.name.toLowerCase()}`"
                            class="nav__link"
                            :data-cta="item?.name"
                            @click="onCloseMobileMenu(item)"
                        >
                            {{ item.name }}
                        </nuxt-link>
                    </li>
                </ul>
            </nav>

            <nav class="header__secondary-nav nav">
                <ul v-if="secondaryBarItems?.length" class="nav__list">
                    <li v-for="item in secondaryBarItems" :key="item.id" class="nav__item">
                        <nuxt-link v-if="item.name" :to="item.value" class="nav__link" @click="onCloseMobileMenu">
                            {{ item.name }}
                        </nuxt-link>
                    </li>
                </ul>
            </nav>
        </div>
    </header>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, useRoute, useRouter } from '#imports'
import type { HeaderNavBar, HeaderNavBarItem } from '~/types/header';
import json from '@/assets/data/menus.json';

const route = useRoute()
const router = useRouter()
const navBarItems = ref<HeaderNavBar[]>()

const secondaryBarItems = ref<HeaderNavBarItem[]>([
    {
        id: '2-1',
        name: 'Cookies'
    },
    {
        id: '2-2',
        name: 'Privacy policy',
        value: '/terms-and-conditions'
    }
])

const mobileMenuOpen = ref(false)
const darkHeader = ref(false)
const hideHeader = ref(false)
const onToggleMobileMenu = () => (mobileMenuOpen.value = !mobileMenuOpen.value)
const onCloseMobileMenu = (item: HeaderNavBarItem) => {
    mobileMenuOpen.value = false
    if (item.name === 'Contact') {
        findContactForm()
    }
}

const lastScrollTop = ref(0)
const showHideHeader = () => {
    const st = window.scrollY
    if (Math.abs(lastScrollTop.value - st) <= 50) { return }
    hideHeader.value = (st > lastScrollTop.value) && (lastScrollTop.value > 0)
    darkHeader.value = window.scrollY > 50
    lastScrollTop.value = st
}

const findContactForm = () => {
    if (route.path === '/') {
        const el = document.getElementById('contact')
        el && window.scrollTo(0, el.offsetTop)
    }
}

onMounted(() => window.addEventListener('scroll', showHideHeader))

onMounted(async () => {
    try {
        navBarItems.value =  json as HeaderNavBar[]
    } catch (e) {
        await router.push('error')
    }
})

onUnmounted(() => window.removeEventListener('scroll', showHideHeader))
</script>


