<template>
    <footer class="footer container">
        <div class="footer__wrapper">
            <nuxt-link to="/" data-arrow="left" class="footer__logo" data-cursor="true">
                © 2022 Spellcaster
            </nuxt-link>

            <nav class="footer__nav nav">
                <ul v-if="footerNav?.length" class="nav__list">
                    <li v-for="item in footerNav" :key="item.id" class="nav__item">
                        <nuxt-link :to="item.link" :data-cta="item?.title">
                            {{ item.title }}
                        </nuxt-link>
                    </li>
                </ul>
            </nav>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { ref } from '#imports'

interface FooterNavItem {
  id: string
  title: string
  link?: string
}

const footerNav = ref<FooterNavItem[]>([
    {
        id: '21oiurj',
        title: 'Cookies'
    },
    {
        id: '21oiuf',
        title: 'Privacy policy',
        link: '/terms-and-conditions'
    }
])
</script>
